import * as React from "react";
import Seo from "components/seo";
import LayoutBanner from "../../templates/layoutBanner";
import Pricing from "../../organisms/pricing";
import { AVAILABLE_FUNCTIONS, PLAN_FAQ, PRICING_TITLE, RATE_PLAN } from "../../constants/data/pricing";
import { graphql } from "gatsby";
import { PRICING_META, PRICING_META_EN } from "../../constants/data/meta_data";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const PricingPage = ({ data }) => {
    const { i18n } = useTranslation();

    const planFaqData = useMemo(() => {
        if (!data[`pricing_faq_data${`_${i18n.language}`}`]) return [];

        return data[`pricing_faq_data${`_${i18n.language}`}`].edges;
    }, [data, i18n]);

    const seoData = useMemo(() => {
        if (i18n.language === "en") return PRICING_META_EN;

        return PRICING_META;
    }, [i18n.language]);

    const planFaq = {
        ...PLAN_FAQ,
        link: {
            ...PLAN_FAQ.link,
            url: `${i18n.language === "en" ? "/en" : ""}/support/faq`
        }
    };

    console.log(planFaq.link.url);

    return (
        <LayoutBanner>
            <Seo title={seoData.title} description={seoData.description} canonical={seoData.canonical} kakaoKeyword={seoData.kakao} />
            <Pricing title={PRICING_TITLE} planCard={RATE_PLAN} planTable={AVAILABLE_FUNCTIONS} planFaq={planFaq} planFaqData={planFaqData} />
        </LayoutBanner>
    );
};

export default PricingPage;

export const PricingFaqData = graphql`
    query ($language: String!) {
        pricing_faq_data_ko: allPricingFaqDataKoCsv {
            edges {
                node {
                    id
                    category
                    title
                    desc
                }
            }
        }
        pricing_faq_data_en: allPricingFaqDataEnCsv {
            edges {
                node {
                    id
                    category
                    title
                    desc
                }
            }
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
