import React, { useState, useRef, useContext } from "react";
import ResponsiveMedia from "utils/responsive_media";
import { Container } from "components/style/layoutStyle";
import { Table, StickyHeader, StickyTabHeader, Row, Cell, ContentCell, HeaderText, ContentContainer, ContentBox, TabWrapper, TabTitle, TabActiveBorder } from "./style";
import { IC_PRICING_BLUE_CHECK, IC_PRICING_TOGGLE_ARROW } from "../../constants/media/icon";
import Image from "components/image/image";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "templates/LocaleBase";

const Toggle = styled.div`
    height: 76px;
    border-radius: 8px;
    background-color: rgba(90, 145, 224, 0.1);

    width: 100%;
    max-width: 1216px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.72px;
    text-align: right;
    color: #5a91e0;
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;
    }

    ${({ toggle }) => toggle && `margin-bottom: 20px;`};
    ${({ isMobile }) => isMobile && `font-size: 16px`};

    ${({ theme, isMobile }) => theme.language === "en" && `font-size: ${!isMobile ? "28px" : "18px"}; lint-height: 1.07`};
`;

const PlanTable = ({ data }) => {
    const { t } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    const { isKo } = useContext(LanguageContext);

    const [planType, setPlantype] = useState(0);

    const availableFunctionCheck = (bool) => {
        switch (bool) {
            case true:
                return <Image filename={IC_PRICING_BLUE_CHECK} alt="check icon" style={{ width: "24px", height: "24px" }} />;
            case false:
                return "-";
            default:
                break;
        }
    };

    const [scrollY, setScrollY] = useState(0);
    const [isSticky, setIsSticky] = useState(false);
    // function handleScroll() {
    //     setScrollY(window.pageYOffset);
    //     if (scrollY >= wrapHeight + titleWrapHeight && scrollY < wrapHeight + titleWrapHeight + tableWrapHeight - 60) {
    //         setIsSticky(true);
    //     } else {
    //         setIsSticky(false);
    //     }
    // }

    // useEffect(() => {
    //     function scrollListener() {
    //         window.addEventListener("scroll", handleScroll);
    //     }
    //     scrollListener();
    //     return () => {
    //         window.removeEventListener("scroll", handleScroll);
    //     };
    // });

    const [toggle, setToggle] = useState(false);

    const option = {};
    const [titleWrapHeight, setTitleWrapHeight] = useState(99999);
    const [tableWrapHeight, setTableeWrapHeight] = useState(99999);
    const titleWrapRef = useRef(null);
    const tableWrapRef = useRef(null);
    // useEffect(() => {
    //     const observer = new IntersectionObserver((entry) => {
    //         if (entry[0].isIntersecting) {
    //             switch (entry[0].target.id) {
    //                 case "title_wrapper":
    //                     setTitleWrapHeight(entry[0].target.scrollHeight);
    //                     break;

    //                 case "table_wrapper":
    //                     setTableeWrapHeight(entry[0].target.scrollHeight);
    //                     break;
    //                 default:
    //                     break;
    //             }
    //         }
    //     }, option);

    //     if (titleWrapRef.current) {
    //         observer.observe(titleWrapRef.current);
    //     }

    //     if (tableWrapRef.current) {
    //         observer.observe(tableWrapRef.current);
    //     }

    //     return () => {
    //         observer.disconnect();
    //     };
    // }, []);

    return (
        <>
            <Container id="table_wrapper" ref={tableWrapRef} flexDirection={"column"} style={{ padding: isMobile ? "0px 20px" : "" }}>
                <Toggle isMobile={isMobile} toggle={toggle}>
                    <div onClick={() => setToggle(!toggle)}>
                        <div>{toggle ? t("COLLAPSE_FEATURE_TABLE") : t("EXPAND_FEATURE_TABLE")}</div>
                        <Image
                            filename={IC_PRICING_TOGGLE_ARROW}
                            style={{
                                width: isMobile ? "24px" : "36px",
                                height: isMobile ? "24px" : "36px",
                                marginLeft: isMobile ? "4px" : "16px",
                                cursor: "pointer",
                                transform: toggle ? "rotate(-180deg)" : ""
                                // transition: "transform 0.2s ease-in"
                            }}
                        />
                    </div>
                </Toggle>
                {toggle && (
                    <Table>
                        {isMobile === "true" ? (
                            <StickyTabHeader sticky={isSticky} isMobile={isMobile}>
                                {data.tab.map((item, index) => {
                                    return (
                                        <TabWrapper key={index} active={planType === index} onClick={() => setPlantype(index)} isMobile={isMobile}>
                                            <TabTitle type={"plan"} margin>
                                                {t(item.title)}
                                            </TabTitle>
                                            <TabTitle type={"type"}></TabTitle>
                                            <TabActiveBorder />
                                        </TabWrapper>
                                    );
                                })}
                            </StickyTabHeader>
                        ) : (
                            <StickyHeader sticky={isSticky}>
                                <Row height={"75px"} style={{ borderTopLeftRadius: "8px", borderTopRightRadius: "8px", border: "solid 1px #ddd", borderBottom: "none" }}>
                                    <Cell />
                                    {data.tab.map((item, index) => {
                                        return (
                                            <Cell key={index}>
                                                {/* <HeaderText type={"title"}>{item.plan}</HeaderText> */}
                                                <HeaderText>{t(item.title)}</HeaderText>
                                            </Cell>
                                        );
                                    })}
                                </Row>
                            </StickyHeader>
                        )}
                        {/* <HeaderDivider /> */}
                        <ContentContainer mobile={isMobile}>
                            {data.items.map((item, index) => {
                                const isLast = data.items.length > index + 1;

                                return (
                                    <ContentBox key={index} borderBottom={isLast ? "solid 1px #ddd" : "none"}>
                                        <Row type={"title"} mobile={isMobile} height={isMobile ? "61px" : "91px"} style={isMobile ? { whiteSpace: "nowrap" } : {}}>
                                            <ContentCell type={"title"} style={isMobile === "true" ? { whiteSpace: "nowrap", fontSize: "18px" } : {}}>
                                                {t(item.title)}
                                            </ContentCell>
                                        </Row>
                                        {item.func.map((info, index) => {
                                            const lineStyle = isMobile && planType === 0 && !info.basic ? { lineHeight: 0, marginBottom: "3px" } : {};

                                            return (
                                                <Row key={index} mobile={isMobile} height={isMobile ? "36px" : "42px"}>
                                                    <ContentCell type={"sub_title"} style={isMobile === "true" ? { whiteSpace: "nowrap", fontSize: "14px", marginTop: "2px" } : {}}>
                                                        {t(info.sub_title)}
                                                    </ContentCell>
                                                    {isMobile === "true" ? (
                                                        <ContentCell type={"info"} style={{ justifyContent: "flex-end", padding: "0 20px 0", flex: "0", ...lineStyle }}>
                                                            {availableFunctionCheck(planType === 0 ? info.basic : info.pro)}
                                                        </ContentCell>
                                                    ) : (
                                                        <>
                                                            <ContentCell type={"info"}>{availableFunctionCheck(info.basic)}</ContentCell>
                                                            <ContentCell type={"info"}>{availableFunctionCheck(info.pro)}</ContentCell>
                                                        </>
                                                    )}
                                                </Row>
                                            );
                                        })}
                                    </ContentBox>
                                );
                            })}
                        </ContentContainer>
                    </Table>
                )}
            </Container>
        </>
    );
};

export default PlanTable;
