import Image from "components/image/image";
import { IC_PRICING_GREEN_CHECK } from "constants/media/icon";
import { navigate } from "gatsby-link";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ResponsiveMedia from "utils/responsive_media";
import CalcPrice from "./calcPrice";

const Container = styled.div`
    padding: 80px 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${({ isMobile }) =>
        isMobile &&
        `
        padding: 100px 20px 60px 20px;
    `};
`;

const TitleIcon = styled.div`
    height: 27px;
    padding: 4px 12px;
    padding-top: 7px;
    border-radius: 4px;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    line-height: 1;

    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.39px;
    text-align: center;
    color: #707070;

    margin-bottom: 8px;

    ${({ theme }) => theme.language === "en" && `font-size: 14px; line-height: 2.14;`};
`;

const Title = styled.div`
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.72px;
    text-align: center;
    color: #1e1e1e;
    margin-bottom: 8px;

    ${({ isMobile }) => isMobile && `font-size: 22px;`};

    ${({ theme }) => theme.language === "en" && `font-size: 28px; line-height: 1.07; margin-bottom: 12px;`};
`;

const PlanCardItemDesc = styled.div`
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.54px;
    text-align: left;
    color: #1e1e1e;
    text-align: center;

    margin-bottom: 32px;

    white-space: pre-line;

    ${({ isMobile }) =>
        isMobile &&
        `
        font-size: 14px;
        margin-bottom: 35px;
    `};

    ${({ theme }) => theme.language === "en" && `line-height: 1.22;`};

    /* & > span {
        margin-left: 8px;
        margin-right: 6px;

        position: relative;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: -0.54px;
        text-align: left;
        color: #5aa645;

        ${({ isMobile }) =>
        isMobile &&
        `
            font-size: 14px;
        `};
    }
    & > span::before {
        content: "";
        position: absolute;
        left: -6px;
        width: 108px;
        height: 26px;
        border-radius: 4px;
        background-color: rgba(90, 166, 69, 0.1);

        ${({ isMobile }) =>
        isMobile &&
        `
            top: -4px;
            left: -7px;
            width: 86px;
            height: 24px;
        `};

        ${({ theme, isMobile }) => theme.language === "en" && (isMobile ? `width: 153px` : `top: 0px; width: 193px;`)};
    } */
`;

const CardItemWrapper = styled.div`
    display: flex;
    gap: 16px;
    margin-bottom: 24px;

    ${({ isMobile }) =>
        isMobile &&
        `
        width: 100%;
        flex-direction: column;
    `};
`;

const CheckCircle = styled.div`
    width: 32px;
    height: 32px;
    margin-bottom: 12px;

    border-radius: 50%;
    background-color: rgba(90, 166, 69, 0.1);

    display: flex;
    align-items: center;
    justify-content: center;

    ${({ isMobile }) => isMobile && `margin-bottom: 0px; margin-right: 16px;`}
`;

const CardItem = styled.div`
    border-radius: 8px;
    background-color: rgba(90, 166, 69, 0.05);
    padding: 32px 57px;
    min-width: 280px;
    /* min-height: 199px; */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    ${({ theme, isMobile }) =>
        theme.language !== "ko" &&
        !isMobile &&
        `
        width: 280px;
        padding: 32px 12px;
    `}

    ${({ isMobile }) =>
        isMobile &&
        `
        padding: 20px;
        min-width: auto;
        min-height: auto;
        flex-direction: row;
    `};

    & > div:last-child {
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.54px;
        text-align: center;
        color: #1e1e1e;

        ${({ theme }) => theme.language === "en" && `line-height: 1.22`};

        ${({ isMobile }) => isMobile && `font-size: 16px; text-align: left;`}
    }

    & > div:last-child > span {
        font-weight: 500;
        color: #5aa645;
    }
`;

const bulkData = [
    {
        src: IC_PRICING_GREEN_CHECK,
        content: "BULK_CONTENT1",
        mobile_content: "BULK_CONTENT1_MOBILE"
    },
    {
        src: IC_PRICING_GREEN_CHECK,
        content: "BULK_CONTENT2",
        mobile_content: "BULK_CONTENT2_MOBILE"
    },
    {
        src: IC_PRICING_GREEN_CHECK,
        content: "BULK_CONTENT3",
        mobile_content: "BULK_CONTENT3_MOBILE"
    }
];

const BulkTable = () => {
    const { t } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    return (
        <Container isMobile={isMobile}>
            <TitleIcon>{t("BULK_TITLE")}</TitleIcon>
            <Title isMobile={isMobile}>BULK {t("PRICING_TEXT")}</Title>
            <PlanCardItemDesc isMobile={isMobile} dangerouslySetInnerHTML={{ __html: isMobile ? t("BULK_DESCRIPTION_MOBILE") : t("BULK_DESCRIPTION") }} />
            <CardItemWrapper isMobile={isMobile}>
                {bulkData.map((x, i) => {
                    return (
                        <React.Fragment>
                            <CardItem isMobile={isMobile}>
                                <CheckCircle isMobile={isMobile}>
                                    <Image filename={x.src} style={{ width: "24px", height: "24px" }} />
                                </CheckCircle>
                                <div dangerouslySetInnerHTML={{ __html: isMobile ? t(x.mobile_content).replace(/\n/g, "<br>") : t(x.content).replace(/\n/g, "<br>") }} />
                            </CardItem>
                        </React.Fragment>
                    );
                })}
            </CardItemWrapper>
            <CalcPrice title={isMobile ? t("BULK_CONTENT_MOBILE_TITLE") : t("BULK_CONTENT_TITLE")} subTitle={t("BULK_CONTENT_SUB_TITLE")} />
        </Container>
    );
};

export default BulkTable;
