import React, { useCallback, useContext, useMemo, useState } from "react";
import styled, { keyframes } from "styled-components";
import { BULK_PLAN, YEAR_PLAN } from "constants/data/pricing";
import { showChannelTalk } from "hooks/useChannelIO";
import ResponsiveMedia from "utils/responsive_media";
import Image from "components/image/image";
import { IC_PRICING_INQUIRY_ARROW } from "constants/media/icon";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "templates/LocaleBase";
import PriceBox from "./parts/priceBox";

const slideIn = keyframes`
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(4px);
  }
`;

const Container = styled.div`
    border: solid 1px #ddd;
    background-color: #fff;
    border-radius: 8px;

    padding: 25px 40px 40px;

    min-width: 904px;
    max-width: 904px;

    ${({ isMobile }) =>
        isMobile &&
        `
        padding: 24px;
        min-width: auto;
        max-width: auto;
        width: 100%;
    `};

    ${({ type }) => type !== "bulk" && `border: none;`}
`;

const HeaderWrpper = styled.div`
    display: flex;
    align-items: center;

    padding-bottom: 20px;

    border-bottom: solid 1px #ddd;

    ${({ isMobile }) => isMobile && `flex-direction: column;`};
`;

const Title = styled.div`
    flex: 1;

    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.54px;
    text-align: left;
    color: #1e1e1e;

    white-space: pre-line;

    ${({ isMobile }) =>
        isMobile &&
        `
        text-align: center;
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: -0.48px;
        margin-bottom: 20px;
    `}

    ${({ theme }) => theme.language === "en" && `font-size: 20px; line-height: 1.5;`};
`;

const InputWrapper = styled.div`
    display: flex;
    align-items: center;

    & > span {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: -0.42px;
        text-align: right;
        color: #1e1e1e;

        margin-left: 8px;
    }
`;

const SubTitle = styled.div`
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.42px;
    text-align: left;
    color: #1e1e1e;

    margin-right: 20px;

    ${({ theme }) => theme.language === "en" && `font-size: 16px; line-height: 1.38`};
`;

const Input = styled.input`
    padding: 8px 14px;
    border: solid 1px #ddd;
    border-radius: 4px;
    transition: border-color 0.2s linear, padding 0.2s linear;

    outline: none;

    width: 156px;
    height: 36px;

    ${({ theme }) => theme.language === "en" && `width: 205px`};

    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: -0.42px;
    text-align: left;
    color: #1e1e1e;
    position: relative;

    :focus {
        border-color: #1e1e1e;
    }

    ${(p) => p.value && ` border-color: #b5b5b5;`};

    ::placeholder {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: -0.42px;
        text-align: left;
        color: #b5b5b5;

        ${({ theme }) => theme.language === "en" && `line-height: 1.57;`};
    }
`;

const PriceContainer = styled.div`
    padding-top: 20px;
`;

const PriceWrapper = styled.div`
    padding: 30px 0px;
    border-radius: 8px;
    background-color: #f8f8f8;

    display: flex;
    align-items: center;

    ${({ isOverTicket }) => isOverTicket && `cursor: pointer;`};

    &:hover,
    &:active {
        ${({ isOverTicket }) => isOverTicket && `background-color: #faf7ef;`};
    }

    &:hover {
        .inquiry_arrow_img {
            animation: ${slideIn} 0.5s ease-in-out;
        }
    }

    ${({ isMobile }) =>
        isMobile &&
        `
        flex-direction: column;
        padding: 24px;
    `};

    ${({ isOverTicket, isMobile, type }) =>
        isOverTicket &&
        isMobile &&
        type === "bulk" &&
        `
        padding-top: 60px;
        padding-bottom: 37px;
    `};

    ${({ isOverTicket, isMobile, type }) =>
        isOverTicket &&
        isMobile &&
        type !== "bulk" &&
        `
        padding-top: 40px;
        padding-bottom: 34px;
    `};
`;

const NonPrice = styled.div`
    width: 150px;
    height: 38px;
    border-radius: 4px;
    background-color: #efefef;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.73;
    letter-spacing: -0.66px;
    text-align: left;
    color: #b5b5b5;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: 32px;
    margin-right: 90px;

    ${({ isMobile }) =>
        isMobile &&
        `
        width: 100%;
        margin: 0px;
        margin-bottom: 20px;
    `}
`;

const Price = styled.div`
    position: relative;

    min-width: 150px;
    height: 38px;

    padding: 8px 16px;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    align-items: center;

    margin-right: 12px;

    ${({ isMobile }) =>
        isMobile &&
        `
        width: 100%;
        height: 40px;
        margin-right: 0px;
        margin-bottom: 8px;
        padding: 6px 14px;
    `}

    &:last-child {
        margin-right: 56px;

        ${({ isMobile }) => isMobile && `margin-right: 0px;`};
    }

    & > div:last-child {
        display: flex;
        flex: 1;
        justify-content: flex-end;

        ${({ theme, isMobile }) => theme.language === "en" && !isMobile && `justify-content: normal`};

        ${({ isMobile }) =>
            isMobile &&
            `
            margin-top: 1px;
            align-items: flex-start;
        `};
    }

    .type {
        height: 21px;

        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;

        letter-spacing: -0.42px;
        text-align: left;
        color: #999;

        ${({ isMobile }) => isMobile && `font-size: 16px;`};

        ${({ theme, isMobile }) => theme.language === "en" && !isMobile && `font-size: 12px; margin-right: 8px; line-height: 2;`};
    }

    .price {
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.89;
        letter-spacing: -0.18px;
        text-align: right;
        color: #1e1e1e;

        ${({ count }) => !count && "color: #999"};
        ${({ isMobile }) => isMobile && `font-size: 22px;`};
    }

    .text {
        margin-left: 2px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.42px;
        text-align: left;
        color: #1e1e1e;

        ${({ isMobile }) => isMobile && `font-size: 16px;`};

        ${({ theme, isMobile }) => theme.language === "en" && !isMobile && `font-size: 12px; flex: 1; text-align: right;`};
    }
`;

const ViewPrice = styled.div`
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.5;
    letter-spacing: -0.32px;
    text-align: left;
    color: #1e1e1e;
    width: 272px;
    padding-left: 40px;

    ${({ isMobile }) =>
        isMobile &&
        `
        padding: 0px;
        width: auto;
        min-height: 30px;
        display: flex;
        align-items: center;
        font-size: 24px;
        margin: 0px;
        margin-bottom: 20px;
    `};
`;

const Inquire = styled.div`
    width: 160px;
    height: 36px;
    margin-left: 42px;
    margin-right: 24px;
    border-radius: 4px;
    background-color: #713838;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: -0.42px;
    text-align: center;
    color: #fff;
    padding-top: 3px;

    cursor: pointer;

    ${({ isMobile }) =>
        isMobile &&
        `
        width: 100%;
        margin: 0px;
        margin-top: 2px;
    `};
`;

const SaleText = styled.div`
    position: absolute;
    top: -22px;
    left: 50px;
    height: 17px;
    padding: 0px 4px;
    border-radius: 4px;
    background-color: rgba(205, 0, 26, 0.05);
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.36px;
    text-align: left;
    color: rgb(205, 0, 26);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding-top: 3px;

    ${({ isMobile }) =>
        isMobile &&
        `
        top: 12px;
        left: 35px;
    `};

    ${({ theme, isMobile }) => theme.language === "en" && !isMobile && `left: 35px;`};
`;

const SaleText2 = styled.div`
    height: 17px;
    padding: 0px 4px;
    border-radius: 4px;
    background-color: rgba(205, 0, 26, 0.05);
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.36px;
    text-align: left;
    color: rgb(205, 0, 26);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding-top: 3px;

    ${({ isMobile }) =>
        isMobile &&
        `
        top: 12px;
        left: 35px;
    `};

    ${({ theme, isMobile }) => theme.language === "en" && !isMobile && `left: 35px;`};
`;

const AddText = styled.div`
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.54px;
    text-align: left;
    color: #707070;

    padding-left: 40px;

    flex: 1;

    white-space: pre-line;

    ${({ isMobile, type }) =>
        isMobile &&
        `
        padding: 0px;
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        padding-bottom: ${type === "bulk" ? "45px" : "20px"};
    `};
`;

const AddTextImgWrapper = styled.div`
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.89;
    letter-spacing: -0.54px;
    text-align: right;
    color: #713838;

    padding-right: 68px;

    display: flex;
    align-items: center;

    ${({ isMobile }) =>
        isMobile &&
        `
        padding: 0px;
    `};

    ${({ theme }) => theme.language === "en" && `line-height: 1.22`};
`;

function roundToNearest(target, roundTo) {
    return Math.round(target / roundTo) * roundTo;
}

const CalcPrice = ({ title, subTitle, type = "bulk" }) => {
    const { t } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    const { isKo } = useContext(LanguageContext);

    const [count, setCount] = useState(null);

    const handleChange = useCallback((event) => {
        if (/^[0-9]*$/.test(event.target.value) || !event.target.value) return setCount(event.target.value);
    }, []);

    const handleKeyDown = (event) => {
        if (event.keyCode === 38 || event.keyCode === 40) {
            event.preventDefault();
        }
    };

    const calcCustomPrice = useMemo(() => {
        const arr = type === "bulk" ? BULK_PLAN : YEAR_PLAN;

        const min = arr[0];
        const max = arr[arr.length - 1];

        if (count < min.count) return min;
        if (count > max.count) return max;

        // const roundedNumber = type === "bulk" ? roundToNearest(count, 1000) : count;
        // let closestPlan = null;
        // let minDifference = Infinity;

        // for (let i = 0; i < arr.length; i++) {
        //     const difference = Math.abs(roundedNumber - arr[i].count);
        //     if (difference < minDifference) {
        //         minDifference = difference;
        //         closestPlan = arr[i];
        //     }
        // }

        // return closestPlan;

        for (let i = 0; i < arr.length; i++) {
            if (count <= arr[i].count) return arr[i];
        }
        // 입력한 숫자보다 큰 count 값을 가진 항목이 없을 경우 마지막 항목을 반환
        return arr[arr.length - 1];
    }, [count, type]);

    const isOverTicket = useMemo(() => {
        return count > (type === "bulk" ? BULK_PLAN[BULK_PLAN.length - 1].count : YEAR_PLAN[YEAR_PLAN.length - 1].count);
    }, [count, BULK_PLAN, YEAR_PLAN]);

    return (
        <Container isMobile={isMobile} type={type}>
            <HeaderWrpper isMobile={isMobile}>
                <Title isMobile={isMobile} dangerouslySetInnerHTML={{ __html: t(title) }} />
                <InputWrapper>
                    {isKo && <SubTitle isMobile={isMobile}>{t(subTitle)}</SubTitle>}
                    <Input placeholder={t("INPUT_PLACEHOLDER")} value={count} onChange={handleChange} onKeyDown={handleKeyDown} maxLength="10" />
                    <span>{isKo ? t("COUNT_TEXT") : t(type === "bulk" ? "MONTHLY_SUB_TEXT" : "ANNUAL_SUB_TEXT")}</span>
                </InputWrapper>
            </HeaderWrpper>
            <PriceContainer isMobile={isMobile}>
                <SubTitle style={{ marginBottom: "8px", textAlign: isMobile ? "center" : "left", marginRight: isMobile ? "0px" : "20px" }}>{t("PRICING_PLAN_TEXT")}</SubTitle>
                <PriceWrapper isMobile={isMobile} isOverTicket={isOverTicket} onClick={() => (isOverTicket ? showChannelTalk() : {})} type={type}>
                    {isOverTicket ? (
                        <>
                            <AddText isMobile={isMobile} style={{ marginTop: isMobile ? "0px" : "3px" }} type={type}>
                                {isMobile ? <span>{t("MOBILE_PRICING_DESCRIPTION_TEXT")}</span> : t("PRICING_DESCRIPTION_TEXT")}
                            </AddText>
                            <AddTextImgWrapper isMobile={isMobile} style={{ marginTop: isMobile ? "0px" : "3px" }}>
                                <div>{t("REQUEST_ESTIMATE_TEXT")}</div>
                                <div className="inquiry_arrow_img">
                                    <Image filename={IC_PRICING_INQUIRY_ARROW} style={{ width: "30px", height: "16px", marginLeft: "8px", marginBottom: "2px" }} />
                                </div>
                            </AddTextImgWrapper>
                        </>
                    ) : (
                        <>
                            {!count ? <NonPrice isMobile={isMobile}>?</NonPrice> : <ViewPrice isMobile={isMobile}>{calcCustomPrice.name}</ViewPrice>}
                            {type === "bulk" && <PriceBox type={type} isMobile={isMobile} count={count} price={count ? calcCustomPrice.month.toLocaleString() : isKo ? "—" : ""} monthly locales={!isKo && isMobile} />}
                            <PriceBox type={type} isMobile={isMobile} count={count} price={count ? calcCustomPrice.year.toLocaleString() : isKo ? "—" : ""} locales={!isKo && isMobile} />
                            {count > 0 && (
                                <div style={{ flex: 1, display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                    <Inquire isMobile={isMobile} onClick={() => showChannelTalk()}>
                                        {t("REQUEST_ESTIMATE_TEXT")}
                                    </Inquire>
                                </div>
                            )}
                        </>
                    )}
                </PriceWrapper>
            </PriceContainer>
        </Container>
    );
};

export default CalcPrice;
