import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Price = styled.div`
    position: relative;

    min-width: 150px;
    height: 38px;

    padding: 8px 16px;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    align-items: center;

    margin-right: 12px;

    ${({ isMobile }) =>
        isMobile &&
        `
        width: 100%;
        height: 40px;
        margin-right: 0px;
        margin-bottom: 8px;
        padding: 6px 14px;
    `}

    &:last-child {
        margin-right: 56px;

        ${({ isMobile }) => isMobile && `margin-right: 0px;`};
    }

    & > div:last-child {
        display: flex;
        flex: 1;
        justify-content: flex-end;

        ${({ theme, isMobile }) => theme.language === "en" && !isMobile && `justify-content: normal`};

        ${({ isMobile }) =>
            isMobile &&
            `
            margin-top: 1px;
            align-items: flex-start;
        `};
    }

    .type {
        margin-top: 1px;
        height: 21px;

        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;

        letter-spacing: -0.42px;
        text-align: left;
        color: #999;

        ${({ isMobile }) => isMobile && `font-size: 16px;`};

        ${({ theme, isMobile }) => theme.language === "en" && !isMobile && `font-size: 12px; margin-right: 8px; line-height: 2;`};

        ${({ locales }) =>
            locales &&
            `
              height: auto;
              font-size: 12px;
              display: flex;
              align-items: center;
              margin-right: 8px;
        `};
    }

    .price {
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.89;
        letter-spacing: -0.18px;
        text-align: right;
        color: #1e1e1e;

        display: flex;
        align-items: center;

        ${({ count }) => !count && "color: #999"};
        ${({ isMobile }) => isMobile && `font-size: 22px;`};
        ${({ locales }) => locales && `font-size: 18px;`};
    }

    .text {
        margin-top: 1px;
        margin-left: 2px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.42px;
        text-align: left;
        color: #1e1e1e;

        ${({ isMobile }) => isMobile && `font-size: 16px;`};

        ${({ theme, isMobile }) => theme.language === "en" && !isMobile && `font-size: 12px; flex: 1; text-align: right;`};

        ${({ locales }) => locales && `font-size: 12px;`};
    }
`;

const SaleText = styled.div`
    position: absolute;
    top: -22px;
    left: 50%;
    transform: translate(-50%, 0);
    height: 17px;
    padding: 3px 4px;
    border-radius: 4px;
    background-color: rgba(205, 0, 26, 0.05);
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.36px;
    text-align: left;
    color: rgb(205, 0, 26);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;

    ${({ isMobile }) =>
        isMobile &&
        `
        top: 12px;
        left: 35px;
    `};

    ${({ theme, isMobile }) => theme.language === "en" && !isMobile && `left: calc(50% - 18px);`};
`;

const SaleText2 = styled.div`
    height: 17px;
    padding: 3px 4px;
    border-radius: 4px;
    background-color: rgba(205, 0, 26, 0.05);
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.36px;
    text-align: left;
    color: rgb(205, 0, 26);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-left: 8px;

    /* ${({ isMobile }) =>
        isMobile &&
        `
        top: 12px;
        left: 35px;
    `}; */

    /* ${({ theme, isMobile }) => theme.language === "en" && !isMobile && `left: 35px;`}; */
`;

const PriceBoxKo = ({ isMobile, count, price, type, monthly }) => {
    const { t } = useTranslation();

    return (
        <Price isMobile={isMobile} count={count}>
            {count > 0 && type === "bulk" && !monthly && <SaleText isMobile={isMobile}>{t("DISCOUNT_TEXT")}</SaleText>}
            <div className="type">{monthly ? t("MONTHLY_TEXT") : t("ANNUAL_TEXT")}</div>
            <div>
                <div className="price">{price}</div>
                <div className="text">{t(monthly ? "MONTHLY_SUB_TEXT" : "ANNUAL_SUB_TEXT")}</div>
            </div>
        </Price>
    );
};

const PriceBoxLocales = ({ isMobile, count, price, type, monthly }) => {
    const { t } = useTranslation();

    return (
        <Price isMobile={isMobile} count={count} locales>
            <div className="type">{t("ANNUAL_TEXT")}</div>
            <div className="price" style={{ display: "flex" }}>
                {price}
                {count > 0 && type === "bulk" && !monthly && <SaleText2 isMobile={isMobile}>{t("DISCOUNT_TEXT")}</SaleText2>}
            </div>
            <div>
                <div className="text">{t(monthly ? "MONTHLY_SUB_TEXT" : "ANNUAL_SUB_TEXT")}</div>
            </div>
        </Price>
    );
};

const PriceBox = ({ isMobile, count, price, type, monthly = false, locales }) => {
    const props = { isMobile, count, price, type, monthly };

    return !locales ? <PriceBoxKo {...props} /> : <PriceBoxLocales {...props} />;
};

export default PriceBox;
