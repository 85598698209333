import React, { useContext, useState } from "react";
import ResponsiveMedia from "utils/responsive_media";

import TextTab from "../parts/textTab";
import { OrganizePlanData } from "./organizePlanData";
import { Box } from "components/style/layoutStyle";
import { CardVATGuiteText, PlanCardItemDesc, PlanCardItemHeader, PlanCardItemTitle, SwitchWrapper } from "./style";
import PlanCardItem from "./planCardItem";
import SwitchButton from "components/button/switchButton";
import Image from "components/image/image";
import { IC_PRICING_SALE } from "constants/media/icon";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "templates/LocaleBase";

const stageColor = ["rgba(205, 0, 26, 0.3)", "rgba(205, 0, 26, 0.4)", "rgba(205, 0, 26, 0.6)", "#cd001a"];

const StyeldTextTab = styled(TextTab)`
    & > div {
        flex: 1;

        > div {
            max-width: 100%;

            > div {
                font-size: 18px;
                padding: 0px 14px;
            }
        }
    }
`;

const PlanCard = ({ data }) => {
    const { t } = useTranslation();

    const { isKo } = useContext(LanguageContext);

    const { isMobile } = ResponsiveMedia();
    const [ratePlan, setRatePlan] = useState(1);
    const [planType, setPlanType] = useState(0);

    const handlePlanType = (event) => {
        setPlanType(event);
    };

    const handlePlanRadio = (value) => {
        setRatePlan(value);
    };

    return (
        <>
            {/* <PlanRadio data={data.ratePlan} onChangeEvent={handlePlanRadio} /> */}
            <div
                style={{
                    backgroundColor: "#ffffff",
                    padding: isMobile ? "0px" : "40px 7.9%",
                    borderRadius: "20px",
                    textAlign: "center",
                    marginTop: isMobile ? "28px" : "60px",
                    display: "flex",
                    flexDirection: "column",
                    width: isMobile ? "100%" : "auto"
                }}
            >
                <PlanCardItemTitle style={{ fontSize: isMobile ? (isKo ? "22px" : "24px") : isKo ? "28px" : "32px" }}>{t("PRICING_CARD_TITLE")}</PlanCardItemTitle>
                <PlanCardItemDesc isMobile={isMobile} dangerouslySetInnerHTML={{ __html: t("PRICING_CARD_DESC") }} />
                <SwitchWrapper>
                    <SwitchButton off={t("PRICING_TAB_TITLE1")} on={t("PRICING_TAB_TITLE2")} width="152px" value={ratePlan === 1} onChange={(value) => handlePlanRadio(value ? 1 : 0)} />
                    <Image filename={IC_PRICING_SALE} alt="check icon" style={{ position: "absolute", width: "40px", height: "27px", top: "-25px", left: "calc(50% + 17px)" }} />
                </SwitchWrapper>
                {!isMobile && (
                    <Box justifycontent={"right"} margin={"-8px 0px 8px 0px"}>
                        <CardVATGuiteText>{t("PRICING_CARD_TABLE_DESC")}</CardVATGuiteText>
                    </Box>
                )}
                {!isMobile && (
                    <div style={{ display: "flex" }}>
                        <PlanCardItemHeader style={{ flex: 1 }}>{t("PRICING_PERSONAL_TEXT")}</PlanCardItemHeader>
                        <PlanCardItemHeader style={{ flex: 4 }}>{t("PRICING_BUSINESS_TEXT")}</PlanCardItemHeader>
                    </div>
                )}
                {isMobile && <StyeldTextTab data={data.tab} margin={"0"} gap={"0px"} onClickEvent={handlePlanType} />}
                <Box gap={"0px"} flexDirection={isMobile === "true" ? "column" : "row"} justifycontent={"center"} padding={isMobile ? "32px 20px 12px 20px" : ""}>
                    {data.items
                        .filter((item) => (isMobile ? planType === item.type : true))
                        .map((item, index) => {
                            const plan = OrganizePlanData(item, ratePlan);

                            return (
                                <PlanCardItem
                                    key={index}
                                    isCorp={plan.type}
                                    borderColor={plan.type ? stageColor[isMobile ? index : index - 1] || "#cd001a" : null}
                                    nameColor={plan.type ? stageColor[isMobile ? index : index - 1] || "#cd001a" : null}
                                    cycle={ratePlan ? 12 : 1}
                                    rechargeCycle={1}
                                    planName={plan.plan_name}
                                    originalPrice={plan.origin_price}
                                    price={plan.plan_price}
                                    countPerPrice={plan.count_per_price}
                                    contract={plan.contract}
                                    template={t(plan.template, { count: plan.templateCount })}
                                    attachment={t(plan.file, { count: plan.fileCount })}
                                    group={plan.group_org}
                                    bulkAndLink={plan.special}
                                    emailAndBranding={plan.brand}
                                    api={false}
                                    commonLimited={isMobile ? index === 3 : index === 4}
                                />
                            );
                        })}
                </Box>
            </div>
        </>
    );
};

export default PlanCard;
