import React, { useCallback } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    position: relative;
    display: inline-flex;
    ${({ width }) => width && `width: ${width}`};
    height: 36px;
    border-radius: 4px;
    background-color: #efefef;
    padding: 4px;
    align-items: center;
    justify-content: center;
`;

const Thumb = styled.div`
    position: absolute;
    top: 2px;
    left: 2px;
    margin: 2px;
    width: calc(50% - 8px);
    height: calc(100% - 8px);
    border-radius: 4px;
    background-color: #ffffff;
    transition: all 0.1s ease-in-out;

    ${({ active }) => active && `left: 50%;`};
`;

const Label = styled.div`
    flex: 1;
    z-index: 1;
    text-align: center;
    font-size: 13px;
    line-height: 1.57;
    transition: all 0.1s linear;
    color: #999999;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    user-select: none;

    display: flex;
    align-items: center;
    justify-content: center;

    ${({ active }) => active && `color: #1e1e1e;`};

    ${({ theme }) => theme.language !== "ko" && `font-size: 14px`};
`;

const Switch = ({ on, off, value, onChange, ...p }) => {
    const handleClick = useCallback(
        (b) => {
            onChange && onChange(b);
        },
        [onChange]
    );

    return (
        <Wrapper {...p}>
            <Thumb active={value} />
            <Label active={!value} onClick={() => handleClick(false)}>
                {off}
            </Label>
            <div style={{ width: "4px" }} />
            <Label active={value} onClick={() => handleClick(true)}>
                {on}
            </Label>
        </Wrapper>
    );
};

Switch.defaultProps = {
    width: "100px",
    value: 0,
    on: "I",
    off: "O"
};

export default React.memo(Switch);
