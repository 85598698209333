import styled from "styled-components";

export const RefWrapper = styled.div`
    width: 100%;
    height: auto;
`;

// plan card buttons
export const PlanRadioWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    > div {
        transition: all 0.2s ease-in-out;
        &:nth-child(1) {
            ${(props) =>
                props.plan === 0
                    ? `
                color: #1e1e1e;`
                    : `
                font-size: 20px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.3;
                letter-spacing: -0.6px;
                text-align: left;
                color: #999999;
            `};
        }

        &:nth-child(2) {
            padding: ${(props) => (props.plan === 0 ? "0 30px 0 0" : "0 0 0 30px")};
            background-color: ${(props) => (props.plan === 0 ? "#b5b5b5" : "#713838")};
        }

        &:nth-child(3) {
            ${(props) =>
                props.plan === 1
                    ? `
                color: #1e1e1e;`
                    : `
                font-size: 20px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.3;
                letter-spacing: -0.6px;
                text-align: left;
                color: #999999;
            `};
        }
    }
`;

export const PlanText = styled.div`
    font-size: ${(props) => props.fontSize};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.6px;
    text-align: left;
`;

export const PlanRadioButtonWrapper = styled.div`
    width: 64px;
    height: 30px;
    margin: 0 16px;
    padding: 4px;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const PlanRadioButton = styled.div`
    width: 22px;
    height: 22px;
    background-color: #fff;
    border-radius: 90px;
`;

export const PromotionText = styled.div`
    width: 108px;
    height: 29px;
    margin: 0 0 0 12px;
    border-radius: 60px;
    background-color: #ffc834;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transform: translate(174px, 0);

    font-size: 14px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.42px;
    text-align: left;
    color: #1e1e1e;
    white-space: nowrap;
`;

// radio mobile

export const PlanRadioMobileWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 15px;

    > div {
        transition: all 0.2s ease-in-out;
        ${(props) =>
            props.active
                ? `
            color: #1e1e1e;`
                : `
            color: #999999;
        `};
    }
`;

export const PlanRadioImageWrapper = styled.div`
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin: 0 6px 0 0;
    cursor: pointer;
`;

// Card

export const CardWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Card = styled.div`
    ${(props) =>
        props.mobile
            ? `
        width: 335px;
        height: 361px;

        > div {
            &:nth-child(1){
                margin: 0 0 36px;
            }
        } 
    `
            : `
        width: 230px;
        height: 353px;
    `}
    border-radius: 8px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
`;

export const CardTopper = styled.div`
    width: 100%;
    height: 10px;
    margin: 0 0 30px;
    border-radius: 8px 8px 0 0;
    background-color: ${(props) => (props.type === 0 ? "#987070" : "#713838")};
`;

export const CardTitleWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${(props) =>
        props.mobile
            ? `
        height: 23px;
        padding: 0 36px;
        margin-bottom: 20px;
    `
            : `
        height: 22px;
        padding: 0 30px;
        margin-bottom: 12px;
    `}
`;

export const CardTitleBadge = styled.div`
    width: 46px;
    height: 22px;
    border-radius: 60px;
    background-color: ${(props) => (props.type === 0 ? "#987070" : "#713838")};
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: -0.36px;
    text-align: left;
    color: #fff;
`;

export const CardTitleText = styled.div`
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.48px;
    text-align: center;
    white-space: nowrap;
    color: #cd001a;
`;

export const CardPlanText = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    ${(props) =>
        props.mobile
            ? `
    `
            : `
        padding: 0 30px;
        margin-bottom: 20px;
    `}

    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.92;
    letter-spacing: normal;
    text-align: center;
    color: ${(props) => (props.type === 0 ? "#987070" : "#713838")};
`;

export const CardDivider = styled.div`
    ${(props) =>
        props.mobile
            ? `
        width: 263px;
        height: 2px;
        background-color: #ddd;
        margin: 0 36px 0;
    `
            : `
        width: 170px;
        height: 1px;
        background-color: #ddd;
        margin-left: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    `}
`;

export const CardPriceBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    position: relative;

    ${(props) =>
        props.mobile
            ? `
        height: 100px;    
        padding: 0 36px;

        > div {
            &:nth-child(1) {
                left: 36px;
            }
        }
    `
            : `
        height: 91px;
        padding: 0 30px;
    `}
`;

export const CardComparePriceText = styled.div`
    height: 19px;
    position: absolute;
    top: 4px;
    left: 30px;

    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.48px;
    text-align: center;
    text-decoration: line-through;
    color: #999;
    visibility: ${(props) => (props.plan === 0 ? "hidden" : "visible")};
`;

export const CardPriceWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: left;
`;

export const CardPriceText = styled.div`
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.51px;
    text-align: left;
    color: #552c2c;
    margin-right: 8px;
`;

export const CardPriceDivision = styled.div`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.42px;
    text-align: left;
    color: #707070;
`;

export const CardPlanInfoBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${(props) =>
        props.mobile
            ? `
        padding: 24px 36px 30px;
        > div div {
            font-size: 16px;
        }
    `
            : `
        padding: 24px 30px 30px;
        > div div {
            font-size: 14px;
        }
    `}

    > div {
        width: 100%;

        > div {
            padding-bottom: 10px;

            &:last-child {
                padding-bottom: 0;
            }
        }
    }
`;

export const CardPlanInfoWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const CardPlanInfoText = styled.div`
    font-weight: ${(props) => (props.position === "title" ? "normal" : "600")};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: -0.42px;
    text-align: left;
    color: ${(props) => (props.type === 0 ? "#999999" : "#1e1e1e")};
`;

export const CardVat = styled.div`
    flex: 1;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: -0.39px;
    text-align: right;
    color: #b5b5b5;
`;

export const CardVATGuiteText = styled.div`
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.36px;
    text-align: right;
    color: #999;

    ${({ theme }) => theme.language === "en" && `font-size: 13px;`};
`;

// plan table

export const Table = styled.div`
    width: 100%;
    max-width: 1216px;
    margin: 0 20px;
    text-align: -webkit-center;
`;

export const StickyHeader = styled.div`
    /* position: sticky; */
    top: 70px;
    z-index: 99;
    background-color: #f9f9f9;
    ${(props) =>
        props.sticky &&
        `
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.08);
    `}
`;

export const StickyTabHeader = styled.div`
    width: 100%;
    height: 62px;
    top: 60px;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px #ddd;
    background-color: #fff;

    ${({ isMobile }) =>
        isMobile &&
        `
        height: 56px;
        border: solid 1px #ddd;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    `};

    ${(props) =>
        props.sticky &&
        `
        z-index: 999999;
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.08);
    `}
`;

export const TabWrapper = styled.div`
    width: 100%;
    max-width: 187px;
    height: 62px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    ${({ isMobile }) => isMobile && `height: 56px;`};

    > div {
        ${(props) =>
            props.active
                ? `
            color: #713838;
        `
                : `  
            color: #999999;
        `}
        &:nth-child(3) {
            ${(props) =>
                props.active
                    ? `
                max-height: 3px;
            `
                    : `  
                max-height: 0px;
            `}
        }
    }
`;

export const TabTitle = styled.div`
    ${(props) =>
        props.type === "plan" &&
        `
        font-size: 18px;
        font-weight: bold;
        line-height: 1.22;
        letter-spacing: -0.54px;
    `}
    ${(props) =>
        props.type === "type" &&
        `
        font-size: 14px;
        font-weight: 500;
        line-height: 1.57;
        letter-spacing: -0.42px;
    `}
    display: flex;
    align-items: center;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    white-space: pre-line;

    ${(props) => props.margin && `margin-top: 10px`};
`;

export const TabActiveBorder = styled.div`
    transition: all 0.1s ease-in-out;
    height: 100%;
    width: 72px;
    background-color: #713838;
    position: absolute;
    bottom: 0;
`;

export const Row = styled.div`
    width: 100%;
    height: ${(props) => props.height || "60px"};
    max-width: 1216px;
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.type === "title" ? "left" : "space-around")};

    ${(props) =>
        props.mobile
            ? `
        > div {
            margin: 0 24px 0;
        }

        > div:first-child {
            margin-right: 0px;
        }

        > div:nth-child(2) {
            margin-left: 0px;
        }
    `
            : `
    
    `};
`;

export const Cell = styled.div`
    flex: 1;
    min-height: 1px;
    text-align: center;
`;

export const ContentCell = styled.div`
    flex: 1;
    ${(props) =>
        props.type === "title" &&
        `display: flex;
        align-items: center;
        justify-content: left;
  
        font-size: ${props.theme.language === "en" ? "28px" : "24px"};
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.72px;
        text-align: left;
        color: #1e1e1e;
        margin: 0 -100px 0 100px`}

    ${(props) =>
        props.type === "sub_title" &&
        `display: flex;
        align-items: center;
        justify-content: left;
        font-size: ${props.theme.language === "en" ? "20px" : "18px"};
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.11;
        letter-spacing: -0.54px;
        text-align: left;
        color: #707070;
        margin: 0 -100px 0 100px`}

    ${(props) =>
        props.type === "info" &&
        `
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.83;
        letter-spacing: -0.72px;
        text-align: center;
        color: #999;
        `}
`;

export const HeaderText = styled.div`
    ${(props) =>
        props.type === "title"
            ? `
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.72px;
        text-align: center;
        color: #713838;
    `
            : ` 
        font-size: ${props.theme.language === "en" ? "28px" : "24px"};
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.72px;
        text-align: center;
        color: #1e1e1e;
    `}
`;

export const HeaderDivider = styled.div`
    width: 100%;
    height: 20px;
`;

export const ContentContainer = styled.div`
    ${(props) =>
        props.mobile
            ? `
        background-color: #fff;
        border: solid 1px #ddd;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top: none;
    `
            : `
        background-color: #fff;
        border: solid 1px #ddd;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    `}
`;

export const ContentBox = styled.div`
    width: 100%;
    padding: 12px 0px 40px 0px;
    border-bottom: ${(props) => props.borderBottom};
`;

// plan faq

export const FaqTableBox = styled.div`
    width: 100%;
    border-bottom: solid 1px #ddd;
`;

export const FaqTableWrapper = styled.div`
    width: 100%;
    transition: all 0.2s ease-in-out;
    border-top: ${(props) => props.borderTop};
    ${(props) =>
        props.mobile
            ? `
        border-top: ${props.open ? "solid 1px #1e1e1e;" : "solid 1px #ddd"};
    `
            : `
        border-top: ${props.open ? "solid 1px #1e1e1e;" : "solid 1px #ddd"};
    `}
    background-color: ${(props) => (props.open ? "#f8f8f8" : "#ffffff")};
`;

export const FaqTableRow = styled.div`
    width: 100%;
    height: auto;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    > * {
        border-bottom: unset !important;
    }
`;

export const FaqTableCell = styled.div`
    display: flex !important;
    align-items: flex-start !important;
    padding: 0px !important;
    border-bottom: none !important;
    ${(props) =>
        props.desc
            ? `
    ${props.theme.language === "ko" ? "font-weight:400" : ""};
    font-size: 16px !important;
    `
            : `
    ${props.theme.language === "ko" ? "font-weight:500" : ""};
    font-size: 18px !important;
    `}
    font-weight: ${({ open }) => (open ? "bold" : "normal")} !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38 !important;
    letter-spacing: -0.48px !important;
    text-align: left !important;
    color: #1e1e1e;
    margin: 20px;
    flex: ${(props) => props.flex};

    transition: font-weight 0.2s ease-in-out;
`;

export const Bold = styled.p`
    font-weight: 500;
    display: flex;
    margin: 0 5px 0 0;
`;

export const FaqBox = styled.div`
    transition: all 0.2s ease-in-out;
    border-bottom: none;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.42px;
    text-align: left;
    color: #1e1e1e;

    width: 100%;
    overflow: hidden;

    ${(props) =>
        props.open
            ? `
        max-height: 500px;
        ${
            props.mobile
                ? `
            padding: 0 20px 0;
        `
                : `
            padding: 12px 20px 0;
        `
        }
    `
            : `
        padding: 0 20px;
        max-height: 0px;
    `}
`;

// Banner
export const BannerContainer = styled.div`
    width: 100%;
    height: 240px;
    background-color: #eceef5;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
    padding: 0 20px;
`;

export const BannerBox = styled.div`
    width: 100%;
    height: 100%;
    max-width: 1216px;
    display: flex;
    ${(props) =>
        props.isMobile
            ? `
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 22px;
    `
            : `
        align-items: center;
        justify-content: space-between;
    `}
`;

export const BannerDescriptionWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;

    ${(props) =>
        props.isMobile
            ? `
        align-items: center;
    `
            : `
        align-items: flex-start;
    `}
`;

export const BannerTitle = styled.div`
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: -0.96px;
    text-align: left;
    color: #202129;

    ${(props) =>
        props.isMobile
            ? `
        font-size: 26px;
    `
            : `
        font-size: 32px;
    `}
`;

export const BannerDescription = styled.div`
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: -0.66px;
    text-align: left;
    color: #202129;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    ${(props) =>
        props.isMobile
            ? `
        font-size: 16px;
    `
            : `
        font-size: 22px;
    `}
`;

export const BannerDescriptionAccentBox = styled.div`
    padding: 6px 8px;
    border-radius: 4px;
    background-color: #4157f720;
`;

export const BannerDescriptionAccent = styled.div`
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.2px;
    text-align: left;
    color: #2a50f2;

    ${(props) =>
        props.isMobile
            ? `
        font-size: 14px;
    `
            : `
        font-size: 20px;
    `}
`;

export const BannerButton = styled.div`
    width: 280px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    background-color: #ffffff;
    border: 1px solid #202129;
    border-radius: 4px;
    cursor: pointer;
`;

export const BannerButtonTitle = styled.div`
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.54px;
    text-align: center;
    color: #202129;

    ${(props) =>
        props.isMobile
            ? `
        font-size: 12px;
    `
            : `
        font-size: 18px;
    `}
`;

export const PlanCardItemTitle = styled.div`
    margin-bottom: 8px;

    font-size: 28px;
    font-weight: bold;
    line-height: 1.07;
    letter-spacing: -0.84px;
    text-align: center;
    color: #1e1e1e;
`;

export const PlanCardItemDesc = styled.div`
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.54px;
    text-align: left;
    color: #1e1e1e;
    text-align: center;

    margin-bottom: 29px;

    ${({ isMobile, theme }) =>
        isMobile &&
        `
        font-size: ${theme.language === "en" ? "16px" : "14px"};
        margin-bottom: 35px;
    `};

    & > span {
        margin-left: 8px;
        margin-right: 6px;

        position: relative;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: -0.54px;
        text-align: left;
        color: #cd001a;

        ${({ isMobile }) =>
            isMobile &&
            `
            font-size: 14px;
        `};
    }

    & > span::before {
        content: "";
        position: absolute;
        left: -6px;
        width: 108px;
        height: 26px;
        border-radius: 4px;
        background-color: rgba(205, 0, 26, 0.05);

        ${({ isMobile }) =>
            isMobile &&
            `
            top: -4px;
            left: -7px;
            width: 86px;
            height: 24px;
        `};

        ${({ theme, isMobile }) => theme.language === "en" && (isMobile ? `width: 112px` : `top: 0px; width: 136px;`)};
    }
`;

export const SwitchWrapper = styled.div`
    position: relative;
    margin-bottom: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PlanCardItemHeader = styled.div`
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: solid 1px #efefef;
    border-bottom: none;

    padding-top: 2px;

    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.36px;
    text-align: center;
    color: #707070;

    ${({ theme }) =>
        theme.language === "en" &&
        `
        font-size: 13px;
        line-height: 2.31;
    `};
`;
