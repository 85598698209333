import { showChannelTalk } from "hooks/useChannelIO";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ResponsiveMedia from "utils/responsive_media";

const Conainer = styled.div`
    width: 100%;
    height: 241px;
    background-color: #f8f8f8;

    margin-top: 40px;
    margin-bottom: 116px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${({ isMobile }) =>
        isMobile &&
        `
        height: auto;
        padding: 40px 20px;

        margin: 0px;
        margin-bottom: 40px;
    `}
`;

const Title = styled.div`
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.72px;
    text-align: center;
    color: #1e1e1e;

    margin-bottom: 16px;

    ${({ isMobile }) =>
        isMobile &&
        `
        margin-bottom: 8px;
        font-size: 22px;
    `};

    ${({ theme }) => theme.language === "en" && `font-size: 28px; lint-height: 1.07`};
`;

const Desc = styled.div`
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.54px;
    text-align: center;
    color: #1e1e1e;

    white-space: pre-line;

    display: flex;
    align-items: center;

    margin-bottom: 24px;

    ${({ isMobile }) =>
        isMobile &&
        `
        line-height: 1.1;
        font-size: 14px;
        flex-direction: column;
    `}
`;

const DescBox = styled.span`
    height: 30px;
    padding: 2px 4px;
    border-radius: 4px;
    background-color: #efefef;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.54px;
    text-align: left;
    color: #707070;

    margin-left: 4px;
    margin-right: 2px;

    ${({ isMobile }) =>
        isMobile &&
        `
        height: 24px;
        font-size: 14px;
    `}
`;

const ItemWrapper = styled.div`
    padding: 8px;
    padding-left: 40px;
    border-radius: 4px;
    background-color: #fff;
    display: inline-flex;
    align-items: center;

    & > div:first-child {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: -0.48px;
        text-align: center;
        color: #1e1e1e;

        ${({ isMobile }) => isMobile && `font-size: 14px;`};
    }

    ${({ isMobile }) =>
        isMobile &&
        `
        width: 100%;
        padding: 16px 16px 20px 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
    `}
`;

const Button = styled.div`
    width: 134px;
    height: 36px;
    border-radius: 4px;
    background-color: #713838;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.42px;
    text-align: center;
    color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    padding-top: 2px;
    margin-left: 24px;

    ${({ isMobile }) => isMobile && `margin: 0; margin-top: 12px;`}

    ${({ theme }) => theme.language === "en" && `font-size: 16px`};
`;

const CustomContent = () => {
    const { t } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    return (
        <Conainer isMobile={isMobile}>
            <Title isMobile={isMobile}>{t("CUSTOM_PLAN_TITLE")}</Title>
            <Desc isMobile={isMobile}>{isMobile ? <>{t("CUSTOM_PLAN_DESC")}</> : <>{t("CUSTOM_PLAN_DESC")}</>}</Desc>
            <Button isMobile={isMobile} onClick={() => showChannelTalk()}>
                {t("CUSTOM_PLAN_BTN_TEXT")}
            </Button>
        </Conainer>
    );
};

export default CustomContent;
