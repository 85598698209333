import React from "react";
import styled from "styled-components";

import { IC_AVAILABLE_BLACK_CEHCK, IC_PRICING_ORIGIN } from "../../constants/media/icon";
import Image from "components/image/image";
import ResponsiveMedia from "utils/responsive_media";
import { useTranslation } from "react-i18next";

const Container = styled.div`
    border: solid 1px #efefef;
    border-top: none;

    display: flex;
    flex-direction: column;

    min-width: 218px;
    min-height: 347px;

    ${({ isMobile }) =>
        isMobile &&
        `
        width: 100%;
        height: auto;
        min-width: 100%;
        min-height: 368px;
        margin-bottom: 20px;
    `};

    & > div:first-child {
        flex: 1;
    }
`;

const Wrapper = styled.div`
    padding: 14px 16px 0px 12px;

    display: flex;
    flex-direction: column;
    align-items: center;

    ${({ isMobile }) =>
        isMobile &&
        `
        align-items: flex-end;
        padding: 24px 24px 0px 24px;
    `};
`;

const ColorBorder = styled.div`
    width: 100%;
    height: 6px;

    background-color: ${({ color }) => color || "#b5b5b5"};
`;

const PlanName = styled.div`
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: ${({ color }) => color || "#999"};

    ${({ isMobile }) =>
        isMobile &&
        `
        width: 100%;
        display: flex;

        & > span {
            flex: 1;
            text-align: left;
        }
    `};

    ${({ theme }) => theme.language === "en" && `font-size: 16px; line-height: 1.5;`};
`;

const OriginalPrice = styled.div`
    height: 24px;

    font-size: 16px;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #b5b5b5;

    position: relative;

    ${({ isMobile }) =>
        isMobile &&
        `
        height: 33px;
        font-size: 22px;
    `};

    & > div:first-child {
        position: absolute;
        top: 50%;
        left: -3px;
    }
`;

const Price = styled.div`
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
    letter-spacing: -0.48px;
    text-align: left;
    color: #1e1e1e;
    margin-bottom: 6px;

    ${({ isMobile }) =>
        isMobile &&
        `
        font-size: 22px;
        margin-bottom: 0px;
    `};

    & > span {
        margin-left: 4px;

        font-size: 26px;
        font-weight: bold;
        line-height: 0.92;
        letter-spacing: normal;
        text-align: center;
        color: #1e1e1e;

        ${({ isMobile }) => isMobile && `font-size: 36px;`};
    }
`;

const CountPerPrice = styled.div`
    padding: 2px 8px;
    border-radius: 4px;
    background-color: #f8f8f8;

    font-size: 13px;
    line-height: 1.38;
    letter-spacing: -0.39px;
    text-align: left;
    color: #707070;
`;

const ContractWrapper = styled.div`
    width: 100%;
    position: relative;

    padding: 18px 0px;

    border-bottom: solid 1px #ddd;

    ${({ isMobile }) =>
        isMobile &&
        `
        border: none;
        padding: 0px;
    `};
`;

const ContentWrapper = styled.div`
    width: 100%;
    padding: 8px 0px;

    ${({ isMobile }) =>
        isMobile &&
        `
        height: auto;
        padding: 24px 0px;
        border-top: solid 1px #ddd;
        margin-top: 20px;
    `};

    & > div:not(:last-child) {
        margin-bottom: 8px;
    }
`;

const ItemWrapper = styled.div`
    width: 100%;
    display: flex;
`;

const ItemTitle = styled.div`
    flex: 1;

    font-size: 14px;
    line-height: 1.57;
    letter-spacing: -0.42px;
    text-align: left;
    color: #707070;

    & > img {
        width: 20px;
        height: 20px;
    }

    ${({ isMobile }) => isMobile && `font-size: 16px;`};
`;

const ItemContent = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
    letter-spacing: -0.42px;
    text-align: right;
    color: #1e1e1e;

    ${({ isMobile }) =>
        isMobile &&
        `
        font-size: 16px;
        display: flex;
        align-items: center;
    `};

    ${({ isMobile, unlimited, theme }) =>
        theme.language === "en" &&
        unlimited &&
        isMobile &&
        `
        font-size: 16px;
        display: flex;
        align-items: flex-end;
        flex-direction: column-reverse;

        .contract_count {
            height: 17px;
        }
    `}
`;

const UnlimitedText = styled.div`
    position: absolute;
    bottom: 2px;
    right: 0px;

    font-size: 12px;
    line-height: 1.83;
    letter-spacing: -0.36px;
    text-align: right;
    color: #cd001a;

    ${({ isMobile, theme }) =>
        isMobile &&
        `
        position: initial;
        margin-right: ${theme.language === "ko" ? "4px" : "0px"};

        height: ${theme.language === "en" ? "7px" : "auto"};
    `};
`;

const PlanCardItem = ({ isCorp, cycle, borderColor, nameColor, planName, originalPrice, price, countPerPrice, contract, rechargeCycle, template, attachment, group, bulkAndLink, emailAndBranding, api, commonLimited }) => {
    const { t } = useTranslation();

    const { isMobile } = ResponsiveMedia();

    return (
        <Container isCorp={isCorp} isMobile={isMobile}>
            <div>
                <ColorBorder color={borderColor} />
                <Wrapper isMobile={isMobile}>
                    {isMobile ? (
                        <PlanName color={nameColor} isMobile={isMobile}>
                            <span>{planName}</span>
                            <CountPerPrice>{t("PRICING_CONTRACT_PRICE_TEXT", { price: countPerPrice })}</CountPerPrice>
                        </PlanName>
                    ) : (
                        <PlanName color={nameColor}>{planName}</PlanName>
                    )}
                    <OriginalPrice isMobile={isMobile}>
                        {cycle === 12 ? (
                            <>
                                <Image filename={IC_PRICING_ORIGIN} alt="check icon" style={{ width: "calc(100% + 10px)", position: "absolute", zIndex: 9 }} />
                                <div style={{ position: "relative", zIndex: 1 }}>&nbsp;{t("PRICING_ORIGINAL_YEAR_PRICE_TEXT", { price: originalPrice.toLocaleString() })}&nbsp;</div>
                            </>
                        ) : (
                            ""
                        )}
                    </OriginalPrice>
                    <Price isMobile={isMobile} dangerouslySetInnerHTML={{ __html: cycle === 1 ? t("PRICING_MONTH_PRICE_TEXT", { price: price.toLocaleString() }) : t("PRICING_YEAR_PRICE_TEXT", { price: price.toLocaleString() }) }} />
                    {!isMobile && <CountPerPrice>{t("PRICING_CONTRACT_PRICE_TEXT", { price: countPerPrice })}</CountPerPrice>}
                    {!isMobile && (
                        <ContractWrapper>
                            <ItemWrapper>
                                <ItemTitle>{t("PRICING_CONTRACT_COUNT_TEXT")}</ItemTitle>
                                <ItemContent>{t("PRICING_MONTH_CONTRACT_COUNT_TEXT", { contract: contract.toLocaleString() })}</ItemContent>
                            </ItemWrapper>
                            {commonLimited && <UnlimitedText>{t("PRICING_UNLIMITED_TEXT")}</UnlimitedText>}
                        </ContractWrapper>
                    )}
                    <ContentWrapper isMobile={isMobile}>
                        {isMobile && (
                            <ContractWrapper isMobile={isMobile}>
                                <ItemWrapper>
                                    <ItemTitle isMobile={isMobile}>{t("PRICING_CONTRACT_COUNT_TEXT")}</ItemTitle>
                                    <ItemContent isMobile={isMobile} unlimited>
                                        {commonLimited && <UnlimitedText isMobile={isMobile}>{t("PRICING_UNLIMITED_TEXT")}</UnlimitedText>}
                                        <div className={commonLimited ? "contract_count" : ""}>{t("PRICING_MONTH_CONTRACT_COUNT_TEXT", { contract })}</div>
                                    </ItemContent>
                                </ItemWrapper>
                            </ContractWrapper>
                        )}
                        <ItemWrapper>
                            <ItemTitle isMobile={isMobile}>{t("PRICING_TEMPLATE_TEXT")}</ItemTitle>
                            <ItemContent isMobile={isMobile}>{template}</ItemContent>
                        </ItemWrapper>
                        <ItemWrapper>
                            <ItemTitle isMobile={isMobile}>{t("PRICING_ATTACHMENT_TEXT")}</ItemTitle>
                            <ItemContent isMobile={isMobile}>{attachment}</ItemContent>
                        </ItemWrapper>
                        {isCorp ? (
                            <>
                                <ItemWrapper>
                                    <ItemTitle isMobile={isMobile}>{t("PRICING_ADD_MEMBERS_TEXT")}</ItemTitle>
                                    <ItemContent isMobile={isMobile}>{group && t("PRICING_PLAN_UNLIMITED_TEXT")}</ItemContent>
                                </ItemWrapper>
                                <ItemWrapper>
                                    <ItemTitle isMobile={isMobile}>{t("PRICING_SEND_CONTRACT_TEXT")}</ItemTitle>
                                    {bulkAndLink && <Image filename={IC_AVAILABLE_BLACK_CEHCK} alt="check icon" style={{ width: isMobile ? "24px" : "16px", height: isMobile ? "24px" : "16px" }} />}
                                </ItemWrapper>
                                <ItemWrapper>
                                    <ItemTitle isMobile={isMobile}>{t("PRICING_EMAIL_BRANDING_TEXT")}</ItemTitle>
                                    {emailAndBranding && <Image filename={IC_AVAILABLE_BLACK_CEHCK} alt="check icon" style={{ width: isMobile ? "24px" : "16px", height: isMobile ? "24px" : "16px" }} />}
                                </ItemWrapper>
                                {api === 1 && (
                                    <ItemWrapper>
                                        <ItemTitle isMobile={isMobile}>API</ItemTitle>
                                        {api && <Image filename={IC_AVAILABLE_BLACK_CEHCK} alt="check icon" style={{ width: isMobile ? "24px" : "16px", height: isMobile ? "24px" : "16px" }} />}
                                    </ItemWrapper>
                                )}
                            </>
                        ) : (
                            ""
                        )}
                    </ContentWrapper>
                </Wrapper>
            </div>
        </Container>
    );
};

export default PlanCardItem;
