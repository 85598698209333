import React, { useContext, useState } from "react";
import ResponsiveMedia from "utils/responsive_media";

import { Container, Box } from "components/style/layoutStyle";
import { TextBold } from "components/style/textStyle";
import TextButton from "components/button/textButton";
import AccordionButton from "components/button/accordionButton";

import { FaqTableWrapper, FaqTableRow, FaqTableCell, FaqBox } from "./style";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "templates/LocaleBase";

const PlanFaq = ({ title, data }) => {
    const { t } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    const { isKo } = useContext(LanguageContext);

    function Row(props) {
        const { row, index } = props;
        const [open, setOpen] = useState(false);

        return (
            <React.Fragment>
                <FaqTableWrapper open={open} mobile={isMobile} borderTop={isMobile && index === 0 && "none !important"}>
                    <FaqTableRow onClick={() => setOpen(!open)}>
                        <FaqTableCell flex={2} desc={isMobile} open={open}>
                            Q.{t(row.title)}
                        </FaqTableCell>
                        <FaqTableCell>
                            <AccordionButton open={open} />
                        </FaqTableCell>
                    </FaqTableRow>
                    <FaqBox open={open} mobile={isMobile}>
                        <FaqTableCell desc style={{ margin: "0 20px 20px 0" }} colSpan={2}>
                            {t(row.desc)}
                        </FaqTableCell>
                    </FaqBox>
                </FaqTableWrapper>
            </React.Fragment>
        );
    }

    return (
        <Container padding={isMobile === "true" ? "80px 20px 100px" : "160px 20px"} alignitems={"flex-start"}>
            <Box alignitems={"flex-start"} flexDirection={isMobile === "true" ? "column" : "row"}>
                <Box maxWidth={isMobile === "true" ? "100%" : "310px"} alignitems={isMobile === "true" ? "center" : "flex-start"} flexDirection={"column"}>
                    <TextBold type={isMobile === "true" ? "26" : isKo ? "36" : "40"} textAlign={isMobile === "true" ? "center" : "flex-start"} margin={isMobile === "true" ? "0 0 13px" : "0 0 24px"}>
                        {t(title.title)}
                    </TextBold>
                    <TextButton mobile={isMobile} justifycontent={isMobile === "true" ? "center" : "flex-start"} margin={"0 0 60px"} data={title.link} />
                </Box>

                <Box flexDirection={"column"} style={{ borderBottom: "1px solid #ddd" }}>
                    {data.map((row, index) => {
                        return <Row key={index} row={row.node} />;
                    })}
                </Box>
            </Box>
        </Container>
    );
};

export default PlanFaq;
