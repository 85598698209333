import React, { useCallback, useContext, useMemo } from "react";

import { Box } from "components/style/layoutStyle";
import { SquareButton, OutlineButton } from "components/button";
import MoveToSignUp from "../../utils/move_to_sign_up";
import OpenLink from "../../utils/open_link";
import { checkIsLogin } from "utils/common";
import moveToDashboard from "utils/move_to_dashboard";
import { globalHistory } from "@reach/router";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "templates/LocaleBase";

const TopServiceButton = () => {
    const isLogin = checkIsLogin();
    const location = globalHistory.location;
    const isPricingPage = location.pathname.includes("pricing");

    const { isKo } = useContext(LanguageContext);

    const { t } = useTranslation();

    const leftText = useMemo(() => {
        if (isLogin && isPricingPage) return t("PRICING_TITLE_MOVE_BTN");
        if (isLogin) return t("LOGIN_TITLE");
        return t("PROMOTION_LEFT_TITLE");
    }, [isLogin, isPricingPage]);

    const onClickLeft = useCallback(() => {
        if (isLogin && isPricingPage) return (window.location.href = process.env.GATSBY_DONUE_PORTAL + "/plan");
        if (isLogin) return moveToDashboard();
        return MoveToSignUp();
    }, [isLogin, isPricingPage]);

    return (
        <Box gap={"16px"} justifycontent={"center"}>
            <SquareButton
                width={"210px"}
                height={"48px"}
                fontSize={isKo ? "18px" : "20px"}
                bgColor={"#713838"}
                hBgColor={"#713838"}
                aBgColor={"#713838"}
                color={"#fff"}
                hColor={"#fff"}
                aColor={"#fff"}
                text={leftText}
                onClickEvent={onClickLeft}
            />
            <OutlineButton
                width={"210px"}
                height={"48px"}
                fontSize={isKo ? "18px" : "20px"}
                borderColor={"#713838"}
                hBorderColor={"#713838"}
                aBorderColor={"#713838"}
                color={"#713838"}
                hColor={"#713838"}
                aColor={"#713838"}
                bgColor={"#fff"}
                hBgColor={"#fff"}
                aBgColor={"#fff"}
                text={t("PRICING_RIGHT_BTN_TEXT")}
                onClickEvent={() => OpenLink("사용자문의", "도입문의", "채널톡")}
            />
        </Box>
    );
};

export default TopServiceButton;
