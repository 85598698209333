import { RATE_PLAN } from "../../constants/data/pricing";

export const OrganizePlanData = (plan, ratePlan) => {
    const type = plan.type;
    const type_desc = RATE_PLAN.tab[type].title;
    const plan_name = plan.plan_name;
    const plan_price = plan.plan_price[ratePlan];
    const count_per_price = Math.ceil(plan_price / (plan.contract === "infinite" ? 1000 : ratePlan ? plan.contract * 12 : plan.contract));
    const origin_price = ratePlan ? plan.plan_price[0] * 12 : 0;
    const template = plan.template === "infinite" ? "PRICING_PLAN_UNLIMITED_TEXT" : "PRICING_LIMITED_PLAN_TEXT";
    const templateCount = plan.template;
    const special = plan.special;
    const brand = plan.brand;

    const contract = plan.contract === "infinite" ? "PRICING_PLAN_UNLIMITED_TEXT" : plan.contract;
    const file = plan.file === "infinite" ? "PRICING_PLAN_UNLIMITED_TEXT" : "PRICING_ATTACHMENT_LIMITED_TEXT";
    const fileCount = plan.file;
    const group_org = plan.type === 0 ? "제공 안함" : plan.group_org + "명";

    const plan_org = {
        type: type,
        type_desc: type_desc,
        plan_name: plan_name,
        origin_price,
        count_per_price,
        plan_price: plan_price,
        contract: contract,
        template,
        file: file,
        group_org: group_org,
        special,
        brand,
        templateCount,
        fileCount
    };

    return plan_org;
};
