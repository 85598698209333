// export const PRICING_TITLE = {
//     title: "요금 안내",
//     subtitle: "회사 계약 업무 방식에 따라 자유롭게 요금제를 선택하세요.",
//     mobileSubtitle: "회사 계약 업무 방식에 따라 <br /> 자유롭게 요금제를 선택하세요."
// };

// export const RATE_PLAN = {
//     tab: [{ title: "개인" }, { title: "기업" }],
//     ratePlan: {
//         title: ["월 결제", "연 결제"]
//     },
//     items: [
//         {
//             type: 0,
//             plan_name: "BASIC",
//             plan_price: [9900, 95000],
//             contract: 10,
//             template: 10,
//             file: 3,
//             group_org: false,
//             special: false,
//             brand: false
//         },
//         {
//             type: 1,
//             plan_name: "PRO 50",
//             plan_price: [19000, 182000],
//             contract: 50,
//             template: "infinite",
//             file: "infinite",
//             group_org: true,
//             special: true,
//             brand: true
//         },
//         {
//             type: 1,
//             plan_name: "PRO 100",
//             plan_price: [29000, 278000],
//             contract: 100,
//             template: "infinite",
//             file: "infinite",
//             group_org: true,
//             special: true,
//             brand: true
//         },
//         {
//             type: 1,
//             plan_name: "PRO 300",
//             plan_price: [49000, 470000],
//             contract: 300,
//             template: "infinite",
//             file: "infinite",
//             group_org: true,
//             special: true,
//             brand: true
//         },
//         {
//             type: 1,
//             plan_name: "Enterprise",
//             plan_price: [159000, 1526000],
//             contract: 1000,
//             template: "infinite",
//             file: "infinite",
//             group_org: true,
//             special: true,
//             brand: true
//         }
//     ]
// };

// export const AVAILABLE_FUNCTIONS = {
//     title: "요금별 기능 비교",
//     sub_title: "본인에게 필요한 기능을 제공하는\n요금을 선택하세요.",
//     tab: [
//         {
//             title: "개인",
//             plan: "BASIC"
//         },
//         {
//             title: "기업",
//             plan: "PRO"
//         }
//     ],
//     items: [
//         {
//             title: "기본 제공 기능",
//             func: [
//                 {
//                     sub_title: "첨부파일 업로드",
//                     basic: true,
//                     pro: true
//                 },
//                 {
//                     sub_title: "실시간 채팅 기능",
//                     basic: true,
//                     pro: true
//                 },
//                 {
//                     sub_title: "이메일/SMS/카카오톡 알림",
//                     basic: true,
//                     pro: true
//                 },
//                 {
//                     sub_title: "나만의 서명 꾸미기",
//                     basic: true,
//                     pro: true
//                 },
//                 {
//                     sub_title: "온라인/전화 지원",
//                     basic: true,
//                     pro: true
//                 }
//             ]
//         },
//         {
//             title: "보안 기능",
//             func: [
//                 {
//                     sub_title: "블록체인 기술로 계약서\n위변조 검증",
//                     basic: true,
//                     pro: true
//                 },
//                 {
//                     sub_title: "감사추적인증서",
//                     basic: true,
//                     pro: true
//                 },
//                 {
//                     sub_title: "본인인증 설정",
//                     basic: true,
//                     pro: true
//                 }
//             ]
//         },
//         {
//             title: "기업 기능",
//             func: [
//                 {
//                     sub_title: "그룹 관리",
//                     basic: false,
//                     pro: true
//                 },
//                 {
//                     sub_title: "그룹원 계약 관리",
//                     basic: false,
//                     pro: true
//                 },
//                 {
//                     sub_title: "대량 전송",
//                     basic: false,
//                     pro: true
//                 },
//                 {
//                     sub_title: "링크 계약",
//                     basic: false,
//                     pro: true
//                 }
//             ]
//         }
//     ]
// };

// export const PLAN_FAQ = {
//     title: "요금제\n자주 묻는 질문",
//     link: {
//         type: "link",
//         title: "고객지원 바로가기",
//         url: "/support/faq"
//     }
// };

// export const BULK_PLAN = [
//     { name: "Bulk 2000", count: 2000, month: 250000, year: 2400000 },
//     { name: "Bulk 3000", count: 3000, month: 336000, year: 3240000 },
//     { name: "Bulk 4000", count: 4000, month: 400000, year: 3840000 },
//     { name: "Bulk 5000", count: 5000, month: 437500, year: 4200000 }
// ];

// export const YEAR_PLAN = [
//     { name: "Year 20", count: 20, month: 0, year: 30000 },
//     { name: "Year 50", count: 50, month: 0, year: 50000 },
//     { name: "Year 100", count: 100, month: 0, year: 90000 },
//     { name: "Year 300", count: 300, month: 0, year: 240000 },
//     { name: "Year 500", count: 500, month: 0, year: 350000 },
//     { name: "Year 800", count: 800, month: 0, year: 480000 },
//     { name: "Year 1000", count: 1000, month: 0, year: 500000 },
//     { name: "Year 2000", count: 2000, month: 0, year: 800000 },
//     { name: "Year 3000", count: 3000, month: 0, year: 900000 },
//     { name: "Year 5000", count: 5000, month: 0, year: 1000000 },
//     { name: "Year 10000", count: 10000, month: 0, year: 1500000 }
// ];

export const PRICING_TITLE = {
    title: "PRICING_TITLE_TITLE",
    title_mobile: "PRICING_TITLE_MOBILE_TITLE",
    subtitle: "PRICING_TITLE_SUBTITLE",
    mobileSubtitle: "PRICING_TITLE_MOBILE_SUBTITLE"
};

export const RATE_PLAN = {
    tab: [{ title: "RATE_PLAN_TAB_PERSONAL_TITLE" }, { title: "RATE_PLAN_TAB_BUSINESS_TITLE" }],
    ratePlan: { title: ["RATE_PLAN_RATE_PLAN_TITLE1", "RATE_PLAN_RATE_PLAN_TITLE2"] },
    items: [
        {
            type: 0,
            plan_name: "BASIC",
            plan_price: [9900, 95000],
            contract: 10,
            template: 10,
            file: 3,
            group_org: false,
            special: false,
            brand: false
        },
        {
            type: 1,
            plan_name: "PRO 50",
            plan_price: [19000, 182000],
            contract: 50,
            template: "infinite",
            file: "infinite",
            group_org: true,
            special: true,
            brand: true
        },
        {
            type: 1,
            plan_name: "PRO 100",
            plan_price: [29000, 278000],
            contract: 100,
            template: "infinite",
            file: "infinite",
            group_org: true,
            special: true,
            brand: true
        },
        {
            type: 1,
            plan_name: "PRO 300",
            plan_price: [49000, 470000],
            contract: 300,
            template: "infinite",
            file: "infinite",
            group_org: true,
            special: true,
            brand: true
        },
        {
            type: 1,
            plan_name: "Enterprise",
            plan_price: [159000, 1526000],
            contract: 1000,
            template: "infinite",
            file: "infinite",
            group_org: true,
            special: true,
            brand: true
        }
    ]
};

export const AVAILABLE_FUNCTIONS = {
    title: "",
    sub_title: "",
    tab: [
        {
            title: "AVAILABLE_FUNCTIONS_TAB_PERSONAL_TITLE",
            plan: "AVAILABLE_FUNCTIONS_TAB_PERSONAL_PLAN"
        },
        {
            title: "AVAILABLE_FUNCTIONS_TAB_BUSINESS_TITLE",
            plan: "AVAILABLE_FUNCTIONS_TAB_BUSINESS_PLAN"
        }
    ],
    items: [
        {
            title: "AVAILABLE_FUNCTIONS_ITEM_1_TITLE",
            func: [
                {
                    sub_title: "AVAILABLE_FUNCTIONS_SUB_TITLE_ATTACH_FILE",
                    basic: true,
                    pro: true
                },
                {
                    sub_title: "AVAILABLE_FUNCTIONS_SUB_TITLE_REAL_TIME_CHAT",
                    basic: true,
                    pro: true
                },
                {
                    sub_title: "AVAILABLE_FUNCTIONS_SUB_TITLE_NOTIFICATIONS",
                    basic: true,
                    pro: true
                },
                {
                    sub_title: "AVAILABLE_FUNCTIONS_SUB_TITLE_CUSTOMIZE_SIGNATURE",
                    basic: true,
                    pro: true
                },
                {
                    sub_title: "AVAILABLE_FUNCTIONS_SUB_TITLE_SUPPORT",
                    basic: true,
                    pro: true
                }
            ]
        },
        {
            title: "AVAILABLE_FUNCTIONS_ITEM_2_TITLE",
            func: [
                {
                    sub_title: "AVAILABLE_FUNCTIONS_SUB_TITLE_SECURITY_1",
                    basic: true,
                    pro: true
                },
                {
                    sub_title: "AVAILABLE_FUNCTIONS_SUB_TITLE_SECURITY_2",
                    basic: true,
                    pro: true
                },
                {
                    sub_title: "AVAILABLE_FUNCTIONS_SUB_TITLE_SECURITY_3",
                    basic: true,
                    pro: true
                }
            ]
        },
        {
            title: "AVAILABLE_FUNCTIONS_ITEM_3_TITLE",
            func: [
                {
                    sub_title: "AVAILABLE_FUNCTIONS_SUB_TITLE_BUSINESS_1",
                    basic: false,
                    pro: true
                },
                {
                    sub_title: "AVAILABLE_FUNCTIONS_SUB_TITLE_BUSINESS_2",
                    basic: false,
                    pro: true
                },
                {
                    sub_title: "AVAILABLE_FUNCTIONS_SUB_TITLE_BUSINESS_3",
                    basic: false,
                    pro: true
                },
                {
                    sub_title: "AVAILABLE_FUNCTIONS_SUB_TITLE_BUSINESS_4",
                    basic: false,
                    pro: true
                },
                {
                    sub_title: "AVAILABLE_FUNCTIONS_SUB_TITLE_BUSINESS_5",
                    basic: false,
                    pro: true
                }
            ]
        }
    ]
};

export const PLAN_FAQ = {
    title: "PLAN_FAQ_TITLE",
    link: {
        type: "link",
        title: "PLAN_FAQ_LINK_TITLE",
        url: "/support/faq"
    }
};

export const BULK_PLAN = [
    { name: "Bulk 2000", count: 2000, month: 250000, year: 2400000 },
    { name: "Bulk 3000", count: 3000, month: 336000, year: 3255000 },
    { name: "Bulk 4000", count: 4000, month: 400000, year: 3840000 },
    { name: "Bulk 5000", count: 5000, month: 437500, year: 4200000 }
];

export const YEAR_PLAN = [
    { name: "Year 20", count: 20, month: 0, year: 30000 },
    { name: "Year 50", count: 50, month: 0, year: 50000 },
    { name: "Year 100", count: 100, month: 0, year: 90000 },
    { name: "Year 300", count: 300, month: 0, year: 240000 },
    { name: "Year 500", count: 500, month: 0, year: 350000 },
    { name: "Year 800", count: 800, month: 0, year: 480000 },
    { name: "Year 1000", count: 1000, month: 0, year: 500000 },
    { name: "Year 2000", count: 2000, month: 0, year: 800000 },
    { name: "Year 3000", count: 3000, month: 0, year: 900000 },
    { name: "Year 5000", count: 5000, month: 0, year: 1000000 },
    { name: "Year 10000", count: 10000, month: 0, year: 1500000 }
];
