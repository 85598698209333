import React from "react";
import styled from "styled-components";

const AccordionButtonBox = styled.div`
width: 16px;
height: 16px;
display: flex;
align-items: center;
justify-content: center;
position: relative;
> div {
    transition: all .3s ease-in-out;
    ${props => props.open? `
        background-color: #1e1e1e;
        &: nth-child(1) {
            transform: scaleY(0);
        }
    `:`
        background-color: #707070;
    `}
}
`

const AccordionButtonV = styled.div`
width: 2px;
height: 16px;
position: absolute;
`

const AccordionButtonH = styled.div`
width: 16px;
height: 2px;
position: absolute;
`

const AccordionButton = (props) => {
    return (
        <AccordionButtonBox open={props.open}>
            <AccordionButtonV />
            <AccordionButtonH />
        </AccordionButtonBox>
    )
}

export default AccordionButton;
